import { useQuery } from '@tanstack/vue-query'
import { QueryOptions } from '~/src/types/QueryOptions'
import { paths } from '~/src/api/schema'

const GET_CURRENCIES = '/api/currencies'

export function getCurrencies ({ params, query }: QueryOptions<paths[typeof GET_CURRENCIES]['get']>) {
  return useQuery({
    ...query,
    queryKey: [
      GET_CURRENCIES
    ],
    queryFn: async ({ signal }) => {
      const client = useApi()
      const { data } = await client.GET(GET_CURRENCIES, {
        params,
        signal
      })
      return data
    }
  })
}
